import { IGroup } from "./group";
import { IIssueType } from "./issuetype";
import { IProductUser } from "./product";
import { IResource } from "./resource";
import { IStationUser } from "./station";
import { ITag } from "./tag";
import { ITraining } from "./training";
import { IUserPrefs } from "./userprefs";

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  username: string;
  title: string;
  fullNameWithTitle: string;
  password: string;
  oldPassword: string;
  confirmPassword: string;
  authId: string;
  twoFactorEnabled: boolean;
  locked: boolean;
  lockedTo: Date;
  roles: string[];
  effectiveRoles?: string[];
  groups: IGroup[];
  userPrefs: IUserPrefs[];
  isLdap: boolean;
  inactive: boolean;
  userStations: IStationUser[];
  userProducts: IProductUser[];
  teacherTrainings: ITraining[];
  traineeTrainings: ITraining[];
  resources: IResource[];
  administeredResources: IResource[];
  notificationTags: ITag[];
  notificationTypes: IIssueType[];
  workerNumber?: string;

  supplierNo?: number;
  supplierName?: string;
}

export function createEmptyUser(): IUser {
  return {
    id: 0,
    firstName: "",
    lastName: "",
    fullName: "",
    title: "",
    fullNameWithTitle: "",
    email: "",
    username: "",
    password: "",
    oldPassword: "",
    confirmPassword: "",
    authId: "",
    twoFactorEnabled: false,
    isLdap: false,
    inactive: false,
    roles: ["basic"],
    effectiveRoles: [],
    locked: false,
    lockedTo: new Date(Date.now()),
    groups: [],
    userStations: [],
    userProducts: [],
    teacherTrainings: [],
    traineeTrainings: [],
    resources: [],
    administeredResources: [],
    notificationTags: [],
    notificationTypes: [],
    userPrefs: [
      {
        id: 0,
        userId: 0,
        lang: "no",
        issueAccordionLength: 10,
        issueGridLength: 15,
        missingPartEmail: true,
      },
    ],
  } as IUser;
}

export const isAdmin = (user: IUser): boolean => {
  return (
    user?.roles?.findIndex((x) => x === "super-admin" || x === "admin") > -1
  );
};

export const isSuper = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "super-admin") > -1;
};

export const isHrUser = (user: IUser): boolean => {
  return user?.roles?.findIndex((x) => x === "admin" || x === "hr") > -1;
};

export const isHmsUser = (user: IUser): boolean => {
  return (
    user?.roles?.findIndex(
      (x) => x === "admin" || x === "hms" || x === "hms-admin"
    ) > -1
  );
};

export const isHmsAdmin = (user: IUser): boolean => {
  return user?.roles?.findIndex((x) => x === "admin" || x === "hms-admin") > -1;
};

export const isQcAdmin = (user: IUser): boolean => {
  return user?.roles?.findIndex((x) => x === "admin" || x === "qc-admin") > -1;
};

export const isQcInspector = (user: IUser): boolean => {
  return user?.roles?.findIndex((x) => x === "qc-inspector") > -1;
};

export const isPurchaser = (user: IUser): boolean => {
  return user?.roles?.findIndex((x) => x === "purchaser" || x === "admin") > -1;
};

export const isChassisAdmin = (user: IUser): boolean => {
  return (
    user?.roles.findIndex((x) => x === "admin" || x === "chassis-admin") > -1
  );
};

export const isSettingsAdmin = (user: IUser): boolean => {
  return (
    user?.roles.findIndex((x) => x === "admin" || x === "qc-settings-admin") >
    -1
  );
};

export const isProtek = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "admin" || x === "protek") > -1;
};

export const isCaseAdmin = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "admin" || x === "case-admin") > -1;
};

export const isTTAdmin = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "admin" || x === "tt-admin") > -1;
};

export const isSupplier = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "supplier") > -1;
};

export const isInternal = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "basic" || x === "admin") > -1;
};

export const isUserAdmin = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "admin" || x === "user-admin") > -1;
};

export const isWelderAdmin = (user: IUser): boolean => {
  return (
    user?.roles.findIndex((x) => x === "admin" || x === "welder-admin") > -1
  );
};

export const isJigAdmin = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "admin" || x === "jig-admin") > -1;
};

export const isResourceAdmin = (user: IUser): boolean => {
  return (
    user?.roles.findIndex((x) => x === "admin" || x === "resource-admin") > -1
  );
};

export const isKitter = (user: IUser): boolean => {
  return (
    user?.roles.findIndex(
      (x) => x === "kitter" || x === "admin" || x === "kitting-admin"
    ) > -1
  );
};

export const isKittingAdmin = (user: IUser): boolean => {
  return (
    user?.roles.findIndex((x) => x === "kitting-admin" || x === "admin") > -1
  );
};

export const isWelder = (user: IUser): boolean => {
  return (
    user?.roles.findIndex(
      (x) => x === "welder" || x === "welder-admin" || x === "admin"
    ) > -1
  );
};

export const isWeldmentInspector = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "weldment-inspector") > -1;
};

export const isQcTeamleader = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "qc-teamleader") > -1;
};

export const canApproveChassis = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "approve-chassis") > -1;
};

export const isServicePoint = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "service") > -1;
};

export const isTechnicalSupport = (user: IUser): boolean => {
  return user?.roles.findIndex((x) => x === "technical-support") > -1;
};
